import Vue from 'vue';
import Component from 'vue-class-component';

import {
  FloatingMenuEvents,
  FloatingMenuItem,
  FilterData,
  FloatingMenuModel,
} from '@/models';

import { EventBus } from '@/utils';

const {
  EDIT,
  DELETE,
  CHECK,
  INPUT,
  SELECT,
  BUTTON_CLICKED,
  OPEN,
} = FloatingMenuEvents;

@Component({
  name: 'FloatingMenuFlow',
})
export default class FloatingMenuFlow extends Vue {
  mounted() {
    this.floatingMenuSubscribeToEvents();
  }

  beforeDestroy() {
    this.floatingMenuUnsubscribeToEvents();
  }

  private floatingMenuSubscribeToEvents() {
    EventBus.$on(EDIT, this.onEdit);
    EventBus.$on(DELETE, this.onDelete);
    EventBus.$on(CHECK, this.onCheck);
    EventBus.$on(INPUT, this.onInput);
    EventBus.$on(SELECT, this.onSelect);
    EventBus.$on(BUTTON_CLICKED, this.onButtonClicked);
    EventBus.$on(OPEN, this.onOpenMenu);
  }

  private floatingMenuUnsubscribeToEvents() {
    EventBus.$off(EDIT, this.onEdit);
    EventBus.$off(DELETE, this.onDelete);
    EventBus.$off(CHECK, this.onCheck);
    EventBus.$off(INPUT, this.onInput);
    EventBus.$off(SELECT, this.onSelect);
    EventBus.$off(BUTTON_CLICKED, this.onButtonClicked);
    EventBus.$off(OPEN, this.onOpenMenu);
  }

  // TODO: talvez seja possível apagar esse método futuramente
  // eslint-disable-next-line
  public onEdit(item: FloatingMenuItem) {
    // CLASS TO OVERRIDE
  }

  // eslint-disable-next-line
  public onDelete(item: FloatingMenuItem) {
    // CLASS TO OVERRIDE
  }

  // eslint-disable-next-line
  public onCheck(item: FloatingMenuItem) {
    // CLASS TO OVERRIDE
  }

  // eslint-disable-next-line
  public onInput(keyword: string) {
    // CLASS TO OVERRIDE
  }

  // eslint-disable-next-line
  public onSelect(item: FloatingMenuItem) {
    // CLASS TO OVERRIDE
  }

  // eslint-disable-next-line
  public onButtonClicked(data: FilterData) {
    // CLASS TO OVERRIDE
  }

  // eslint-disable-next-line
  public onOpenMenu(data: FloatingMenuModel, show: boolean) {
    // CLASS TO OVERRIDE
  }
}
