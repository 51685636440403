
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Pagination } from '@/models/api';
import ArrowLeft from '@/assets/icons/ArrowLeft.svg';
import ArrowRight from '@/assets/icons/ArrowRight.svg';

@Component({
  name: 'Paginator',
  components: {
    ArrowLeft,
    ArrowRight,
  },
})
export default class Paginator<T = unknown> extends Vue {
  @Prop() data: Pagination<T>

  public showPageNumber(page: number): boolean {
    const { current_page = 1, last_page = 1 } = this.data;

    return page === current_page
    || page === 1
    || page === last_page
    || (page >= current_page - 2 && page <= current_page + 2);
  }

  public showPage(page: number): boolean {
    const { current_page } = this.data;

    return this.showPageNumber(page)
    || (page >= (current_page as number) - 3 && page <= (current_page as number) + 3);
  }

  public changePage(page: number) {
    if (page === this.data.current_page) return;
    this.$emit('change', page);
  }
}
